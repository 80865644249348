import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";
import LogRocket from "logrocket";

ReactDOM.render(
  <ErrorBoundary
    onClick={() => {
      localStorage.clear();
      window.location.reload();
    }}
    handleComponentDidCatch={(error, errorInfo) => {
      // window.gtag("event", "exception", {
      //   code: error.code,
      //   userType: this.props.userType,
      //   message: error.message,
      //   fatal: true
      // });
      LogRocket.captureMessage(error.message, {
        tags: {
          userType: "public",
        },
        extra: {
          fatal: true,
          code: error.code,
        },
      });
    }}
  >
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById("root")
);
