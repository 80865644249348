import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "./firebase";

export const getLocations = async () => {
  const docRef = collection(db, "locations");
  const snapshot = await getDocs(docRef);
  return snapshot.docs.map((doc) => ({ ...doc.data(), documentId: doc.id }));
};

export const updateCompliant = async (documentId, data) => {
  await updateDoc(
    doc(db, "complaints", documentId),
    {
      assets: data,
    },
    { merge: true }
  );
  return documentId;
};
