export const api = {
  baseUrl:
    process.env.REACT_APP_STAGING === "development"
      ? "https://api.dev.gzero.byepo.com"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "https://api.uat.gzero.byepo.com"
      : process.env.REACT_APP_STAGING === "production"
      ? "https://api.rsbpct.com"
      : "http://127.0.0.1:5000",
};

export const Regex = {
  pinCode: /^[1-9][0-9]{5}$/gm,
  aadhaarNumber: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
  phoneNumber: /^[6-9]\d{9}$/,
  healthId: /^(?=[a-zA-Z])([a-zA-Z0-9]){2,13}[a-zA-Z]$/i,
  // eslint-disable-next-line
  email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
};

export const dataTypeConversion = (dataType) => {
  if (dataType === "string") {
    return "Alphanumeric (All characters)";
  } else if (dataType === "Alphanumeric (All characters)") {
    return "string";
  } else if (dataType === "float") {
    return "Number (Digits)";
  } else if (dataType === "Number (Digits)") {
    return "float";
  } else if (dataType === "timestamp") {
    return "Date (Date)";
  } else if (dataType === "Date (Date)") {
    return "timestamp";
  } else {
    return "";
  }
};

export function dateAndTimeConverter(timestamp, type) {
  if (!timestamp) return;

  if (type === "cardDate") {
    let dateObject = new Date(timestamp);
    const date =
      dateObject.toLocaleString("en-IN", { day: "numeric" }).toUpperCase() +
      " " +
      dateObject.toLocaleString("en-IN", { month: "short" }).toUpperCase() +
      " " +
      dateObject.toLocaleString("en-IN", { weekday: "long" }) +
      " ";
    return date;
  }
  if (type === "cardDateWithoutDay") {
    let dateObject = new Date(timestamp);
    const date =
      dateObject.toLocaleString("en-IN", { day: "numeric" }).toUpperCase() +
      " " +
      dateObject.toLocaleString("en-IN", { month: "short" }).toUpperCase() +
      " " +
      dateObject.toLocaleString("en-IN", { year: "2-digit" }) +
      " ";
    return date;
  } else {
    if (type === "firstDate") {
      let dateObject = new Date(timestamp);
      const date = dateObject
        .toLocaleString("en-IN", { day: "numeric" })
        .toUpperCase();
      return date;
    }
    if (type === "secondDate") {
      let dateObject = new Date(timestamp);
      const date =
        dateObject.toLocaleString("en-IN", { day: "numeric" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { month: "short" }).toUpperCase() +
        " " +
        dateObject.toLocaleString("en-IN", { year: "numeric" });
      return date;
    }
  }

  if (type === "Time") {
    let dateObject = new Date(timestamp);
    const date =
      dateObject
        .toLocaleString("en-IN", {
          hour: "numeric" && "2-digit",
          minute: "numeric" && "2-digit",
          hour12: true,
        })
        .toUpperCase() + " ";
    return date;
  }
}

export const convertTimestampToDate = (data) => {
  // 1999-1-30
  return {
    day: parseInt(data.split("-")[2]),
    month: parseInt(data.split("-")[1]),
    year: parseInt(data.split("-")[0]),
  };
};

export const logRocketInit = {
  key:
    process.env.REACT_APP_STAGING === "development"
      ? "byepo/gzero-public-dev"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "byepo/gzero-public-uat"
      : process.env.REACT_APP_STAGING === "production"
      ? "byepo/gzero-public-prod"
      : "",
};

export const bucketNames = {
  defaultBucket:
    process.env.REACT_APP_STAGING === "development"
      ? "gs://pct-dev-380105.appspot.com"
      : process.env.REACT_APP_STAGING === "UAT"
      ? "gs://pct-uat.appspot.com"
      : process.env.REACT_APP_STAGING === "production"
      ? "gs://pct-prod.appspot.com"
      : "gs://pct-dev-380105.appspot.com",
};
