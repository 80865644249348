export const throwError = (code, message) => {
  let instance = new Error(code, message);
  instance.code = code;
  instance.message = message;
  return instance;
};

export function errorHandler(error) {
  console.error(error);
  let result = {};
  switch (error.code) {
    case "auth":
      result.code = error.code;
      result.message = error.message;
      break;
    case "auth/timeout":
      result.code = error.code;
      result.message = "Request timed out. Please try again later";
      break;
    case "auth/invalid-credential":
      result.code = error.code;
      result.message = "Invalid credentials. Please check your OTP";
      break;
    case "auth/invalid-verification-code":
      result.code = error.code;
      result.message = "Invalid credentials. Please check your OTP";
      break;
    case "auth/invalid-verification-id":
      result.code = error.code;
      result.message = "Invalid credentials. Please check your OTP";
      break;
    case "auth/captcha-check-failed":
      result.code = error.code;
      result.message = "Captcha assessment failed. Please try again";
      break;
    case "auth/invalid-phone-number":
      result.code = error.code;
      result.message = "Please enter a valid phone number";
      break;
    case "auth/missing-phone-number":
      result.code = error.code;
      result.message = "Please enter a valid phone number";
      break;
    case "auth/quota-exceeded":
      result.code = error.code;
      result.message = "Usage limit exceeded. Please try again later";
      break;
    case "auth/invalid-user-token":
      result.code = error.code;
      result.message = "Session expired. Please login";
      break;
    case "auth/user-token-expired":
      result.code = error.code;
      result.message = "Session expired. Please login";
      break;
    case "auth/network-request-failed":
      result.code = error.code;
      result.message = "Connectivity error. Please try again later";
      break;
    case "auth/requires-recent-login":
      result.code = error.code;
      result.message = "Session duration too long. Please re-login";
      break;
    case "auth/too-many-requests":
      result.code = error.code;
      result.message = "Usage limit exceeded. Please try again later";
      break;
    case "auth/web-storage-unsupported":
      result.code = error.code;
      result.message = "Unsupported web browser";
      break;
    case "auth/id-token-expired":
      result.code = error.code;
      result.message = "Session expired. Please login";
      break;
    case "auth/id-token-revoked":
      result.code = error.code;
      result.message = "Session expired. Please login";
      break;
    //input error
    case "input/image-size-exceeded":
      result.code = error.code;
      result.message = "Image size should be less than 2MB";
      break;
    case "input/file-size-exceeded":
      result.code = error.code;
      result.message = "Document size should be less than 5MB";
      break;
    //firestore database error codes
    case "cancelled":
      result.code = `database/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    //custom error from frontend
    case "custom":
      result.code = error.code;
      result.message = error.message;
      break;
    case "deadline-exceeded":
      result.code = `database/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "not-found":
      result.code = `database/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "already-exists":
      result.code = `database/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "permission-denied":
      result.code = `database/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "resource-exhausted":
      result.code = `database/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "failed-precondition":
      result.code = `database/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "aborted":
      result.code = `database/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "out-of-range":
      result.code = `database/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "unimplemented":
      result.code = `database/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "internal":
      result.code = `database/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "unavailable":
      result.code = `database/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "data-loss":
      result.code = `database/${error.code}`;
      result.message = "Data corrupted. Please try again later";
      break;
    case "invalid-argument":
      result.code = `database/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "search":
      result.code = `database/${error.code}`;
      result.message = error.message;
      break;
    //form validation errors
    case "input":
      result.code = `input`;
      result.message = error.message;
      break;
    // Storage errors
    case "object-not-found":
      result.code = `storage/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "bucket-not-found":
      result.code = `storage/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "unauthenticated":
      result.code = `storage/${error.code}`;
      result.message = "Session Expired. Please login to continue";
      break;
    case "unauthorized":
      result.code = `storage/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "retry-limit-exceeded":
      result.code = `storage/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "canceled":
      result.code = `storage/${error.code}`;
      result.message = "Process Cancelled";
      break;
    case "invalid-event-name":
      result.code = `storage/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "invalid-url":
      result.code = `storage/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "server-file-wrong-size":
      result.code = `storage/${error.code}`;
      result.message = "Something went wrong. Please try again later";
      break;
    case "400":
      result.code = `api/${error.code}`;
      result.message = error.message;
      break;
    default:
      if (
        error.code === undefined ||
        !error.code.includes("auth") ||
        !error.code.includes("database") ||
        !error.code.includes("input") ||
        !error.code.includes("storage")
      ) {
        result.code = `unknown/${error.code}`;
      } else {
        result.code = error.code;
      }
      result.message = "Something went wrong. Please try again later";
      break;
  }
  return result;
}
